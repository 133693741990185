import { Script } from 'gatsby';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { PressKitSection } from 'src/sections';
import { Page, Section } from 'src/components';
import { ENV, isClient } from 'src/utils';
import SessionContext from 'src/context/SessionContext';

const Code = styled.code`
  padding: 20px 24px 20px 24px;
  display: block;
  background: var(--fgaa);
  font-weight: 500;
  font-size: 14px;
  border-radius: var(--radius-lg);
  margin-bottom: 12px;
`;

const ROOT = isClient() ? window.location.origin : ENV.pxUrl?.replace('/explorer', '');
const FALLBACK_PARTNER = 'test';

const PartnerTools = () => {
  const { partner } = useContext(SessionContext);
  const PARTNER = partner || FALLBACK_PARTNER; // uses bahs as fallback
  const [pxUrl] = useState(`${ROOT}/explorer`);
  const [appUrl] = useState(ENV.appUrl);

  return (
    <Page
      title="Catch Partner Tools"
      description="Assets, embeds, custom URLs, and more"
      prefooter={<></>}
      color="#F0F3F5"
      darkColor="#24272E"
      image="/img/og/default.png"
    >
      <PressKitSection three title="Partner Kit" />
      <Section title="Tools">
        <h3>Content Library</h3>
        <a className="large link" href="/blog">
          (placeholder) Blog
        </a>
        <br />
        <a className="large link" href="/guides">
          (placeholder) Guides
        </a>

        <h3>Custom Signup URL</h3>

        <Code>
          {appUrl}
          <br />
          ?r={`{code}`}
          <br />
          &partner={`{partner_name}`}
          <br />
          &email=partners@catch.co
          <br />
          &zip=27024
          <br />
          &phone=18005551234
        </Code>

        <h3>Health Plans Embed</h3>
        <Code>{`<iframe src="${pxUrl}?partner=${PARTNER}" />`}</Code>
        <iframe
          src={`${pxUrl}?partner=${PARTNER}`}
          width="100%"
          height="650"
          style={{
            border: '2px solid var(--fg-subtle)',
            borderRadius: `var(--radius-lg)`,
          }}
        />

        <h3>Health Zip Embed</h3>
        <Code>
          {`<iframe src="${pxUrl}/zip?partner=${PARTNER}&theme=light&r=some_code&zip=27024&email=partners@catch.co" />`}
        </Code>
        <iframe
          src={`${pxUrl}/zip?partner=${PARTNER}&theme=light&r=some_code&zip=27024&email=partners@catch.co`}
          width="100%"
          height="650"
          style={{
            border: '2px solid var(--fg-subtle)',
            borderRadius: `var(--radius-lg)`,
          }}
        />

        <h3>Partner Page Embed</h3>
        <Code>
          {`<iframe src="${ROOT}/partners/${PARTNER}?&theme=light&zip=27024&email=partners@catch.co" />`}
        </Code>
        <iframe
          src={`${ROOT}/partners/${PARTNER}?&theme=light&zip=27024&email=partners@catch.co`}
          width="100%"
          height="650"
          style={{
            border: '2px solid var(--fg-subtle)',
            borderRadius: `var(--radius-lg)`,
          }}
        />

        <h3>Resizing</h3>
        <p>To support proper resizing, attach the following ID on your iframe implementation:</p>
        <Code>{`<iframe id="catch-iframe" src="${ROOT}" />`}</Code>

        <p>
          Then, make sure to include the following script in the head of your page. This will listen
          for changes to the height of the content and adjust the height of your iframe.
        </p>
        <Code>{`<script src="https://s.catch.co/catch-partner.js" />`}</Code>

        <iframe
          id="catch-iframe"
          src={`${ROOT}`}
          width="100%"
          height="650"
          style={{
            border: '2px solid var(--fg-subtle)',
            borderRadius: `var(--radius-lg)`,
          }}
        />
        <Script src="https://s.catch.co/catch-partner.js" />
      </Section>
    </Page>
  );
};

export default PartnerTools;
